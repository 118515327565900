import React, { useState } from 'react'

import { navigate, RouteComponentProps } from '@reach/router'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import Checkout from 'components/app/user/checkout'
import PaymentModule from 'components/shared/payment-module'
import PaymentCard from 'components/shared/project/payment/card'
import { getDefaultPayment } from 'context/payment/utils'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import { parseStaff } from 'context/project/utils'
import useUser from 'context/user/use'
import useUserProjectMutate from 'context/user/use-project-mutate'
import AppLayout from 'design-system/containers/app-layout'
import { StyledForm } from 'styles/app/components/form'
import { PaymentMethodType, PaymentType } from 'types/payment'
import { PaymentSource } from 'types/payment-source'
import { Project, ProjectType } from 'types/project'
import { ProjectStaffRole } from 'types/project-staff'

const Module = (_: RouteComponentProps) => {
  const { inTradeProgram, user } = useUser()
  const { createProjectAndRefetch, loadingCreate } = useUserProjectMutate()
  const [designerSelected, setDesignerSelected] = useState('')

  const projectType = inTradeProgram ? ProjectType.TRADE : ProjectType.HOMEOWNER
  const payment = getDefaultPayment(PaymentType.DESIGN_DEPOSIT, inTradeProgram)

  const onPay = async (
    paymentSource?: PaymentSource,
    paymentMethod?: PaymentMethodType,
  ) => {
    try {
      const { data } = await createProjectAndRefetch({
        variables: {
          data: {
            data: {
              projectType,
            },
            owners: {
              create: [
                {
                  isSubscribed: true,
                  isPrimary: true,
                  userRole: user.type,
                  user: {
                    create: {
                      email: user.email,
                      type: user.type,
                    },
                  },
                },
              ],
            },
            payments: {
              create: {
                amount: payment.amount,
                description: payment.description,
                metadata: {
                  ...payment.metadata,
                  method: paymentMethod,
                },
                stripeSourceId: paymentSource?.id,
              },
            },
            staff: designerSelected
              ? {
                  create: [
                    {
                      isSubscribed: true,
                      isPrimary: true,
                      staffMember: {
                        connect: { id: designerSelected },
                      },
                      staffMemberRole: ProjectStaffRole.SALES_DESIGNER,
                    },
                  ],
                }
              : undefined,
          },
        },
      })
      navigate(`/app/projects/${data?.createOneProject.id}`)
    } catch {
      // let error show and do nothing
    }
  }

  return (
    <PaymentModule
      isTradeProject={inTradeProgram}
      paymentRequest={
        <>
          <StyledForm styled={{ whiteInputs: true }}>
            <Form.Input
              disabled
              label="Email"
              name="email"
              placeholder="Email"
              required
              value={user.email}
            />
            {!inTradeProgram ? (
              <FormSelect
                fluid
                label="Choose your designer"
                name="designer"
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  { value }: { value: string },
                ) => setDesignerSelected(value)}
                options={getSalesDesignerOptions(
                  user.projects.map((p) => p.project as Project),
                )}
                placeholder="Choose your designer"
              />
            ) : null}
          </StyledForm>
          <Checkout
            buttonText="Create New Project"
            includeInvoice={false}
            includeTermsAndConditions={inTradeProgram}
            loading={loadingCreate}
            footnote="*This deposit is always redeemable towards your final cabinetry purchase and is not refundable once samples have been ordered or design work has been started."
            onPay={onPay}
            summary={<PaymentCard payment={payment} />}
            summaryTitle="Summary"
          />
        </>
      }
      paymentType={PaymentType.PROJECT_DEPOSIT}
      title={
        inTradeProgram
          ? 'Design your kitchen with FORM for $499'
          : 'Start working with a FORM designer for $499'
      }
    />
  )
}

export default (props: RouteComponentProps) => {
  return (
    <AppLayout>
      <PaymentSourceManyProvider>
        <Module {...props} />
      </PaymentSourceManyProvider>
    </AppLayout>
  )
}

const getSalesDesignerOptions = (projects: Project[]) => {
  const designers: {
    key: string
    text: string
    value: string
    image?: { avatar: boolean; src: string }
  }[] = [
    {
      key: 'null',
      text: `Assign me a new designer`,
      value: '',
    },
  ]

  for (const p of projects) {
    const { primaryDesigner } = parseStaff(p)
    if (!designers.some((o) => o.value === primaryDesigner?.id))
      designers.push({
        key: primaryDesigner?.id ?? '',
        text: `${primaryDesigner?.firstName} ${primaryDesigner?.lastName}`,
        value: primaryDesigner?.id ?? '',
        image: { avatar: true, src: primaryDesigner?.picture ?? '' },
      })
  }

  return designers
}

const FormSelect = styled(Form.Select)`
  &&&& {
    div.dropdown {
      .text {
        height: 35px !important;
        padding-top: 12px !important;
      }
    }
  }
`
