import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Dropdown, Image } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useUser from 'context/user/use'
import HeaderContainer from 'design-system/containers/header'
import { Hamburger } from 'design-system/containers/header/hamburger'
import { FormLogoItem } from 'design-system/containers/header/header'
import HeaderMenu from 'design-system/containers/header/header-menu'

const Header = ({ onRequestProject }: { onRequestProject?: () => void }) => {
  const { user: userCookie, logout } = useAuth()
  const { user: userDb, inTradeProgram, company } = useUser()

  const user = userDb || userCookie

  if (!user) return null

  const UserMenu = () => (
    <>
      <Dropdown.Item onClick={() => navigate(`/app/profile`)} text="Profile" />
      {company?.id && (
        <Dropdown.Item
          onClick={() => navigate(`/app/company/${company.id}/main`)}
          text="Company"
        />
      )}
      {inTradeProgram && (
        <Dropdown.Item text="Request new project" onClick={onRequestProject} />
      )}
      <Divider className="no-margin" />
      <Dropdown.Item onClick={logout} text="Log out" />
    </>
  )

  const handleLogoClick = async () => {
    await navigate('/app')
  }

  return (
    <HeaderContainer
      leftItems={<FormLogoItem onClick={handleLogoClick} />}
      rightItems={
        <>
          <HeaderMenu
            direction="left"
            trigger={<Image className="avatar" src={user?.picture} />}
          >
            <UserMenu />
          </HeaderMenu>

          {/* Mobile */}
          <Hamburger>
            <UserMenu />
          </Hamburger>
        </>
      }
    />
  )
}

export default Header
