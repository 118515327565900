import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText:
      'To begin design revisions, <span class="bold">the next step is the $499 Project Deposit</span>. This unlocks more time with your designer to finalize your kitchen and discuss extra rooms.',
    image: `images/interactive-guides/design-presentation/next-step-design-presentation-1.svg`,
  },
  {
    leadText:
      'After payment, your designer will send a <span class="bold">follow-up message on how to access your detailed design and prepare for your first check-in call</span> to kickstart the revisions process.',
    description:
      'For most people, they find it best to sit with a detailed design for a few days before meeting again to agree on a list of changes.',
    image: `images/interactive-guides/design-presentation/next-step-design-presentation-2.svg`,
  },
  {
    leadText:
      'There are <span class="bold">3 design revisions included</span> for the kitchen.',
    description:
      'Only 5% of our clients require more than three revisions.<br /> Additional revisions are available at cost ($199 per revision).',
    image: `images/interactive-guides/design-presentation/next-step-design-presentation-3.svg`,
  },
  {
    leadText: 'First <span class="bold">3 extra rooms are included.</span>',
    description:
      'Additional or complex room designs are available ($199 per room, redeemable towards that room).',
    image: `images/interactive-guides/design-presentation/next-step-design-presentation-4.svg`,
  },
  {
    leadText:
      'For each design revision, you’ll be able to review <span class="bold">detailed drawings and itemized pricing.</span>',
    description:
      'Additional photorealistic visuals are available at cost ($199 per set per room).',
    image: `images/interactive-guides/design-presentation/next-step-design-presentation-5.svg`,
  },
]

const NextSteps = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel
          isTradeProject={isTradeProject}
          slideData={nextSteps}
        />
      </Grid>
    </Section>
  )
}

export default NextSteps
