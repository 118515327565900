import React from 'react'

import Button from 'design-system/components/button'
import { Project, ProjectType } from 'types/project'
import { User } from 'types/user'

import DesignerHeader from './designer-header'

type DesignerHeaderHomeProps = {
  project: Project
  designer?: User
}

const DesignerHeaderHome = ({ project, designer }: DesignerHeaderHomeProps) => {
  const isTradeProject = project.data?.projectType === ProjectType.TRADE

  return (
    <DesignerHeader
      title={`I'm ${designer?.firstName}, your ${
        isTradeProject ? 'trade designer' : 'designer'
      }`}
      designerPicture={designer?.picture ?? ''}
      actions={
        <>
          {!isTradeProject && (
            // TODO: UPDATE DANNY CALENDAR
            <Button
              kind="text"
              text="Speak to manager"
              fontAwesomeIcon="comment-exclamation"
              color="gray"
              iconAnimation="scale"
              onClick={() =>
                window.open(
                  'https://calendly.com/form-kitchens-danny-soos/speak-to-management',
                )
              }
            />
          )}
        </>
      }
    />
  )
}

export default DesignerHeaderHome
