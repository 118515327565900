import React, { createContext, useMemo } from 'react'

import { parseRoomData } from 'context/room/utils'

import useProject from '../use'

export enum PreProjectDepositCardTypes {
  APPLIANCES = 'appliances',
  APPROVE_DESIGN_BRIEF = 'approveDesignBrief',
  CALL_RECAPS = 'callRecaps',
  CATALOGS = 'catalogs',
  COLLABORATORS = 'collaborators',
  COMPLETE_PROJECT_SETUP = 'completeProjectSetup',
  DESIGN_CALL_RECAP = 'designCallRecap',
  DESIGN_PRESENTATION_RECAP = 'designPresentationRecap',
  FLOOR_PLAN = 'floorPlan',
  INITIAL_COSTING = 'initialCosting',
  INSPIRATION = 'inspiration',
  INSPIRATION_GUIDE = 'inspirationGuide',
  ORDER_SAMPLES = 'orderSamples',
  PAY_DESIGN_DEPOSIT = 'payDesignDeposit',
  PAY_PROJECT_DEPOSIT = 'payProjectDeposit',
  PROJECT_CONSULTATION_RECAP = 'projectConsultationRecap',
  PROJECT_HANDBOOK = 'projectHandbook',
  PROJECT_PROPOSAL = 'projectProposalRecap',
  ROOM_PHOTOS = 'roomPhotos',
  SAMPLES = 'samples',
  SCHEDULE_DESIGN_CALL = 'scheduleDesignCall',
  SCHEDULE_PROJECT_CONSULTATION = 'scheduleProjectConsultation',
}

type PreProjectDepositProviderProps = {
  children: React.ReactNode
}

type IPreProjectDepositContext = {
  keyDocumentsSection: PreProjectDepositCardTypes[]
  resourcesSection: PreProjectDepositCardTypes[]
  toDoSection: PreProjectDepositCardTypes[]
}

export const PreProjectDepositContext =
  createContext<IPreProjectDepositContext>({} as IPreProjectDepositContext)

const PreProjectDepositProvider = ({
  children,
}: PreProjectDepositProviderProps) => {
  const {
    designBriefs,
    initialCostings,
    isTradeProject,
    meetingDesignOccurred,
    meetingDesignScheduled,
    meetingPresentationOccurred,
    meetingProjectConsultationOccurred,
    meetingProjectProposalOccurred,
    orderSamplesPlaced,
    paymentDesignDepositPaid,
    paymentProjectDepositPaid,
    paymentsProjectDepositNotSucceeded,
    projectProposals,
    roomPrimary,
  } = useProject()

  const { completeInformationFlowDone } = useMemo(
    () => parseRoomData(roomPrimary, true),
    [roomPrimary],
  )

  const contextValue = useMemo<IPreProjectDepositContext>(() => {
    const toDoSection: PreProjectDepositCardTypes[] = []
    const keyDocumentsSection: PreProjectDepositCardTypes[] = []
    const resourcesSection: PreProjectDepositCardTypes[] = [
      PreProjectDepositCardTypes.CATALOGS,
      PreProjectDepositCardTypes.COLLABORATORS,
      PreProjectDepositCardTypes.INSPIRATION_GUIDE,
      PreProjectDepositCardTypes.PROJECT_HANDBOOK,
    ]

    //* To Do Section: Trade
    if (isTradeProject) {
      if (paymentsProjectDepositNotSucceeded.length) {
        toDoSection.push(PreProjectDepositCardTypes.PAY_PROJECT_DEPOSIT)
      }
    } else {
      //* To Do Section: Homeowner
      if (!paymentProjectDepositPaid) {
        toDoSection.push(PreProjectDepositCardTypes.PAY_PROJECT_DEPOSIT)
      }
    }

    //* Resources Section
    if (!paymentProjectDepositPaid) {
      if (completeInformationFlowDone) {
        resourcesSection.push(
          PreProjectDepositCardTypes.APPLIANCES,
          PreProjectDepositCardTypes.FLOOR_PLAN,
          PreProjectDepositCardTypes.ROOM_PHOTOS,
          PreProjectDepositCardTypes.INSPIRATION,
        )
      }
    }

    return {
      keyDocumentsSection,
      resourcesSection,
      toDoSection,
    }
  }, [
    completeInformationFlowDone,
    designBriefs,
    initialCostings,
    isTradeProject,
    meetingDesignOccurred,
    meetingDesignScheduled,
    meetingPresentationOccurred,
    meetingProjectConsultationOccurred,
    meetingProjectProposalOccurred,
    orderSamplesPlaced,
    paymentDesignDepositPaid,
    paymentProjectDepositPaid,
    projectProposals,
  ])

  return (
    <PreProjectDepositContext.Provider value={contextValue}>
      {children}
    </PreProjectDepositContext.Provider>
  )
}

export const usePreProjectDeposit = () => {
  const context = React.useContext(PreProjectDepositContext)
  if (context === undefined) {
    throw new Error(
      'usePreProjectDeposit must be used within a PreProjectDepositProvider',
    )
  }
  return context
}

export default PreProjectDepositProvider
