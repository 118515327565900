import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid, Image } from 'semantic-ui-react'

import DashboardPreProjectDepositCard from 'components/app/project/dashboard-pre-project-deposit-card'
import Header from 'components/app/user/header'
import NewProjectModal from 'components/app/user/request-new-project'
import { ChipToggle } from 'components/shared/chip'
import HelpMessage from 'components/shared/help-message'
import useCompany from 'context/company/use'
import { notifySuccess } from 'context/notifications/trigger'
import { PreProjectDepositCardTypes } from 'context/project/pre-project-deposit/provider'
import useCardsUrls from 'context/project/pre-project-deposit/use-cards-urls'
import { parseStaff } from 'context/project/utils'
import useUser from 'context/user/use'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import Button from 'design-system/components/button'
import { SecondaryCard } from 'design-system/components/cards'
import AppLayout from 'design-system/containers/app-layout'
import { ToggleCheckbox } from 'styles/app/components/checkbox'
import { Breakpoint } from 'styles/app/system'

import ProjectCardTrade from './project-card'

const S3_ROOT = process.env.GATSBY_CDN_ROOT

const TradeHome = (_: RouteComponentProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { user, parsedUserProjects, projects } = useUser()
  const { updateUser } = useUserMutateNoContext()
  const { company, parsedCompanyProjects } = useCompany()
  const [showAllProjects, toggleProjects] = useState<boolean>(false)
  const [showRequestModal, toggleRequestModal] = useState<boolean>(false)
  const [filter, setFilter] = useState<'all' | 'inProgress' | 'ordered'>('all')

  const { primaryDesigner } = parseStaff(projects[0])
  const hasProjects = projects.length > 0
  const getCardUrl = useCardsUrls()

  return (
    <AppLayout
      header={<Header onRequestProject={() => toggleRequestModal(true)} />}
    >
      <Grid stackable>
        <Grid.Row columns="equal">
          <Grid.Column>
            <h3 className="no-margin">{company?.name}</h3>
            {hasProjects && (
              <Button
                color="gray"
                kind="text"
                text="Request New Project"
                fontAwesomeIcon="plus"
                onClick={() => toggleRequestModal(true)}
              />
            )}
            <NewProjectModal
              designer={primaryDesigner}
              onClose={() => toggleRequestModal(false)}
              open={showRequestModal}
              onRequest={async () => {
                await updateUser({
                  variables: {
                    data: {
                      data: {
                        ...user.data,
                        requestedProjectAt: new Date(),
                        requestProjectTo: primaryDesigner?.id,
                      },
                    },
                    where: { id: user.id },
                  },
                })
                notifySuccess(
                  'Your designer will be notified, that you are requesting a new project',
                )
                toggleRequestModal(false)
              }}
            />
          </Grid.Column>
          {hasProjects && (
            <Grid.Column textAlign={isMobile ? 'left' : 'right'}>
              <ToggleCheckbox
                onChange={() => toggleProjects(!showAllProjects)}
                checked={showAllProjects}
                toggle
                label="Showing All Company Projects"
              />
            </Grid.Column>
          )}
        </Grid.Row>
        {hasProjects ? (
          <>
            <Grid.Row>
              <Grid.Column>
                <div
                  className="flex"
                  style={{ gap: '16px', marginBottom: '12px' }}
                >
                  {!showAllProjects ? (
                    <Image
                      circular
                      src={user.picture}
                      style={{ width: '40px' }}
                    />
                  ) : null}
                  <h5 className="no-margin">All projects</h5>
                </div>
                <div className="flex" style={{ gap: '16px' }}>
                  <ChipToggle
                    active={filter === 'all'}
                    onClick={() => setFilter('all')}
                    name="Show All"
                  />
                  <ChipToggle
                    active={filter === 'inProgress'}
                    onClick={() => setFilter('inProgress')}
                    name="In Progress"
                  />
                  <ChipToggle
                    active={filter === 'ordered'}
                    onClick={() => setFilter('ordered')}
                    name="Ordered"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched className="row-gap">
              {(showAllProjects
                ? parsedCompanyProjects[filter]
                : parsedUserProjects[filter]
              ).map((p, id) => (
                <Grid.Column key={id} computer={5} mobile={16} tablet={8}>
                  <ProjectCardTrade project={p} showUsers={showAllProjects} />
                </Grid.Column>
              ))}
            </Grid.Row>
          </>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column>
                <HelpMessage
                  message="Welcome to the FORM Trade Program. Schedule a project
                  consultation to get paired with one of our trade designers and
                  get started on your first project."
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched className="row-gap">
              <Grid.Column computer={5} mobile={16} tablet={8}>
                <DashboardPreProjectDepositCard
                  isTopSection
                  getCardUrl={getCardUrl}
                  cardType={
                    PreProjectDepositCardTypes.SCHEDULE_PROJECT_CONSULTATION
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        <Grid.Row>
          <Grid.Column>
            <h5>Resources</h5>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched className="row-gap">
          <Grid.Column computer={5} mobile={16} tablet={8}>
            <SecondaryCard
              image={`${S3_ROOT}/images/app/illustrations/catalogs.svg`}
              onClick={() => navigate('/app/catalogs')}
              subtitle="Product Information"
              title="Catalogs"
            />
          </Grid.Column>
          <Grid.Column computer={5} mobile={16} tablet={8}>
            <SecondaryCard
              image={`${S3_ROOT}/images/app/illustrations/installation-materials.svg`}
              onClick={() => navigate('/app/installation-materials')}
              subtitle="Videos and Guides"
              title="Installation Materials"
            />
          </Grid.Column>
          <Grid.Column computer={5} mobile={16} tablet={8}>
            <SecondaryCard
              image={`${S3_ROOT}/images/app/illustrations/lead-times.svg`}
              onClick={() => navigate('/app/timeline')}
              subtitle="Timeline tool"
              title="Lead Times"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default TradeHome
