import React from 'react'

import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import DashboardPreProjectDepositCard from 'components/app/project/dashboard-pre-project-deposit-card'
import DesignerHeader from 'components/app/project/designer-header'
import NotificationBar from 'components/shared/notification-bar'
import { usePreProjectDeposit } from 'context/project/pre-project-deposit/provider'
import useCardsUrls from 'context/project/pre-project-deposit/use-cards-urls'
import useProject from 'context/project/use'
import Button from 'design-system/components/button/button'
import { Drawer } from 'design-system/components/drawer'
import { Breakpoints } from 'styles/home/system'

const PreProjectDepositDashboard = () => {
  const { primaryDesigner, project, roomPrimary } = useProject()
  const { resourcesSection, keyDocumentsSection, toDoSection } =
    usePreProjectDeposit()

  const getCardUrl = useCardsUrls(roomPrimary.id)

  return (
    <Container>
      <Grid>
        <Grid.Row columns={2} verticalAlign="middle">
          <Grid.Column largeScreen={9} computer={10} tablet={16} mobile={16}>
            <DesignerHeader
              title={`Hi, it's ${primaryDesigner?.firstName}, your designer`}
              designerPicture={primaryDesigner?.picture ?? ''}
              actions={
                <Button
                  kind="text"
                  color="gray"
                  text="View project timeline guide"
                  fontAwesomeIcon="arrow-right"
                  onClick={() => {
                    navigate(`/app/projects/${project.id}/timeline`)
                  }}
                  iconAnimation="right"
                />
              }
            />
          </Grid.Column>
          <NotificationColumn
            floated="right"
            largeScreen={6}
            computer={6}
            tablet={16}
            mobile={16}
          >
            {/* Add notifications here */}
            {primaryDesigner?.data?.awayMessage ? (
              <NotificationBar text={primaryDesigner.data.awayMessage} />
            ) : null}
          </NotificationColumn>
        </Grid.Row>
      </Grid>
      <div className="cards-top">
        {toDoSection.map((section) => (
          <DashboardPreProjectDepositCard
            isTopSection
            getCardUrl={getCardUrl}
            cardType={section}
            key={`top-section.${section}`}
          />
        ))}
      </div>

      {!!keyDocumentsSection.length && (
        <Drawer
          content={
            <div className="cards">
              {keyDocumentsSection.map((document) => (
                <DashboardPreProjectDepositCard
                  getCardUrl={getCardUrl}
                  cardType={document}
                  key={`key-document-section.${document}`}
                />
              ))}
            </div>
          }
          title="Key documents"
          defaultOpen
        />
      )}
      <Drawer
        content={
          <div className="cards">
            {resourcesSection.map((resource) => (
              <DashboardPreProjectDepositCard
                getCardUrl={getCardUrl}
                cardType={resource}
                key={`resources-section.${resource}`}
              />
            ))}
          </div>
        }
        title="Resources"
        defaultOpen
      />
    </Container>
  )
}

export default PreProjectDepositDashboard

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  div.cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    gap: 24px;

    @media ${Breakpoints.downFromComputer} {
      grid-template-columns: 1fr 1fr;
    }

    @media ${Breakpoints.downFromSmallMobile} {
      grid-template-columns: 1fr;
    }
  }

  div.cards-top {
    display: grid;
    grid-template-columns: repeat(auto-fit, 600px);
    flex-wrap: wrap;
    gap: 24px;

    @media ${Breakpoints.downFromComputer} {
      grid-template-columns: auto auto;
    }

    @media ${Breakpoints.downFromSmallMobile} {
      grid-template-columns: auto;
    }
  }
`

const NotificationColumn = styled(Grid.Column)`
  @media ${Breakpoints.downFromComputer} {
    margin: 30px 0 0 !important;
  }
`
