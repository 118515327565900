import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import useAuth from 'context/auth/use'
import useDesign from 'context/design/use'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import PrimaryCard from 'design-system/components/cards/primary-card'
import { Section } from 'design-system/components/guide/utils'
import { RoomType } from 'types/room'
import { getRoomImageByType } from 'views/utils'

interface DesignPlanProps {
  projectId: string
  roomId: string
}

const DesignPlan = ({ projectId, roomId }: DesignPlanProps) => {
  const { isFORMStaff } = useAuth()
  const { paymentProjectDepositPaid } = useProject()
  const { room } = useRoom()
  const { design, latestPlan, latestCountertopEstimate } = useDesign()

  return (
    <Section id="design">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Your design</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row centered stretched className="row-gap">
          <Grid.Column computer={4} tablet={7} mobile={16}>
            <PrimaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote.svg`}
              onClick={
                design?.id
                  ? () =>
                      navigate(
                        `/app/projects/${projectId}/rooms/${roomId}/designs/${design.id}/quote`,
                      )
                  : undefined
              }
              disabled={!design?.id}
              title="Visual Quote"
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={7} mobile={16}>
            <PrimaryCard
              disabled={
                (!isFORMStaff && !paymentProjectDepositPaid) ||
                !design?.id ||
                !latestPlan?.id
              }
              image={getRoomImageByType(room?.type as RoomType)}
              onClick={
                design?.id && latestPlan?.id
                  ? () =>
                      navigate(
                        `/app/projects/${projectId}/rooms/${roomId}/designs/${design.id}/plan/${latestPlan.id}`,
                      )
                  : undefined
              }
              title="Design Plan"
            />
          </Grid.Column>
          {latestCountertopEstimate?.id && (
            <Grid.Column computer={4} tablet={7} mobile={16}>
              <PrimaryCard
                disabled={
                  (!isFORMStaff && !paymentProjectDepositPaid) ||
                  !design?.id ||
                  !latestCountertopEstimate?.id
                }
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/countertop-estimate.svg`}
                onClick={
                  design?.id && latestCountertopEstimate?.id
                    ? () =>
                        window.open(
                          `${process.env.GATSBY_CDN_ROOT}/${latestCountertopEstimate?.key}`,
                        )
                    : undefined
                }
                title="Countertop Estimate"
              />
            </Grid.Column>
          )}
        </Grid.Row>
        {!paymentProjectDepositPaid ? (
          <Grid.Row>
            <Grid.Column computer={10} tablet={14} mobile={16}>
              <HelpMessage message="The design plan will only be available on your dashboard after paying the $499 project deposit" />
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    </Section>
  )
}

export default DesignPlan
