import React from 'react'

import { navigate } from 'gatsby'

import Checkout from 'components/app/user/checkout'
import PaymentModule from 'components/shared/payment-module'
import PaymentCard from 'components/shared/project/payment/card'
import useAuth from 'context/auth/use'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import ThankYouCard from 'design-system/components/guide/thank-you-card'
import { Section } from 'design-system/components/guide/utils'
import { PaymentType } from 'types/payment'

const DesignDeposit = () => {
  const { isFORMStaff } = useAuth()
  const {
    paymentsDesignDepositSucceeded,
    paymentsDesignDepositNotSucceeded,
    project,
  } = useProject()
  const { updatePaymentAndRefetch, loadingUpdate } = useProjectPaymentMutate()

  const payment =
    paymentsDesignDepositNotSucceeded?.[0] ||
    paymentsDesignDepositSucceeded?.[0]

  return (
    <Section id="design-deposit">
      <PaymentSourceManyProvider>
        <PaymentModule
          isTradeProject
          hidePaymentRequest={
            (!paymentsDesignDepositSucceeded.length && isFORMStaff) || !payment
          }
          paymentRequest={
            paymentsDesignDepositSucceeded.length ? (
              <ThankYouCard
                onClick={() => navigate(`/app/projects/${project.id}`)}
              />
            ) : (
              <Checkout
                buttonText="Pay and Start Project"
                includeInvoice={false}
                loading={loadingUpdate}
                onPay={async (paymentSource, paymentMethod) => {
                  if (!payment?.id) return
                  await updatePaymentAndRefetch({
                    variables: {
                      data: {
                        metadata: {
                          ...payment.metadata,
                          method: paymentMethod,
                        },
                        stripeSourceId: paymentSource?.id,
                      },
                      where: {
                        id: payment.id,
                      },
                    },
                  })
                }}
                summary={<PaymentCard payment={payment} />}
                summaryTitle="Summary"
              />
            )
          }
          paymentType={PaymentType.PROJECT_DEPOSIT}
          title="Start working with a FORM designer for $499"
        />
      </PaymentSourceManyProvider>
    </Section>
  )
}

export default DesignDeposit
