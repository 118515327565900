import React from 'react'

import { navigate } from 'gatsby'

import { PreProjectDepositCardTypes } from 'context/project/pre-project-deposit/provider'
import { SecondaryCard, TaskCard } from 'design-system/components/cards'

const S3_ROOT = process.env.GATSBY_CDN_ROOT

type DashboardCardProps = {
  cardType: PreProjectDepositCardTypes
  isTopSection?: boolean
  getCardUrl: (cardType: PreProjectDepositCardTypes) => {
    url?: string
    newTab?: boolean
  }
}

const DashboardPreProjectDepositCard = ({
  cardType,
  getCardUrl,
  isTopSection,
}: DashboardCardProps) => {
  const { url, newTab } = getCardUrl(cardType)

  const handleClick = url
    ? () =>
        newTab
          ? window.open(url)
          : navigate(url, { state: { comingFromHome: true } })
    : undefined

  const Card = isTopSection ? TaskCard : SecondaryCard

  switch (cardType) {
    case PreProjectDepositCardTypes.APPLIANCES:
      return (
        <Card
          onClick={handleClick}
          title="Appliances"
          icon="oven"
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.APPROVE_DESIGN_BRIEF:
      return (
        <Card
          title="Approve Design Brief"
          onClick={handleClick}
          disabled={!url}
          image={`${S3_ROOT}/images/app/illustrations/approve-design-brief.svg`}
        />
      )
    case PreProjectDepositCardTypes.CALL_RECAPS:
      return (
        <Card
          title="Call Recaps"
          onClick={handleClick}
          disabled={!url}
          icon="file-video"
        />
      )
    case PreProjectDepositCardTypes.CATALOGS:
      return (
        <Card
          title="Catalogs"
          onClick={handleClick}
          icon="copy"
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.COLLABORATORS:
      return (
        <Card
          title="Collaborators"
          icon="users"
          onClick={handleClick}
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.COMPLETE_PROJECT_SETUP:
      return (
        <Card
          title="Complete Your Project Setup"
          image={`${S3_ROOT}/images/app/illustrations/complete-project-setup.svg`}
          onClick={handleClick}
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.DESIGN_CALL_RECAP:
      return (
        <Card
          disabled={!url}
          onClick={() => window.open(url)}
          title="Initial Call"
          icon="file-search"
        />
      )
    case PreProjectDepositCardTypes.DESIGN_PRESENTATION_RECAP:
      return (
        <Card
          disabled={!url}
          onClick={() => window.open(url)}
          title="Design Presentation"
          icon="file-search"
        />
      )
    case PreProjectDepositCardTypes.FLOOR_PLAN:
      return (
        <Card
          title="Floor Plan"
          icon="object-group"
          onClick={handleClick}
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.INITIAL_COSTING:
      return (
        <Card
          title="Initial Costing"
          onClick={handleClick}
          disabled={!url}
          icon="money-check-edit-alt"
        />
      )
    case PreProjectDepositCardTypes.INSPIRATION:
      return (
        <Card
          title="Inspiration"
          icon="heart"
          onClick={handleClick}
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.INSPIRATION_GUIDE:
      return (
        <Card
          title="Inspiration Guide"
          icon="book-heart"
          onClick={handleClick}
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.ORDER_SAMPLES:
      return (
        <Card
          title="Order Samples"
          onClick={handleClick}
          disabled={!url}
          image={`${S3_ROOT}/images/app/illustrations/samples-red.svg`}
        />
      )
    case PreProjectDepositCardTypes.SAMPLES:
      return (
        <Card
          title="Samples"
          onClick={handleClick}
          disabled={!url}
          icon="game-board-alt"
        />
      )
    case PreProjectDepositCardTypes.PAY_DESIGN_DEPOSIT:
      return (
        <Card
          title="Pay Design Deposit"
          onClick={handleClick}
          disabled={!url}
          image={`${S3_ROOT}/images/app/illustrations/pay-design-deposit.svg`}
        />
      )
    case PreProjectDepositCardTypes.PAY_PROJECT_DEPOSIT:
      return (
        <Card
          title="Purchase Design Package"
          onClick={handleClick}
          disabled={!url}
          image={`${S3_ROOT}/images/app/illustrations/pay-project-deposit.svg`}
        />
      )
    case PreProjectDepositCardTypes.PROJECT_CONSULTATION_RECAP:
      return (
        <Card
          disabled={!url}
          onClick={() => window.open(url)}
          title="Project Consultation"
          icon="file-search"
        />
      )
    case PreProjectDepositCardTypes.PROJECT_HANDBOOK:
      return (
        <Card
          title="Project Handbook"
          onClick={handleClick}
          icon="ballot-check"
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.PROJECT_PROPOSAL:
      return (
        <Card
          disabled={!url}
          onClick={() => window.open(url)}
          title="Project Proposal"
          icon="file-search"
        />
      )
    case PreProjectDepositCardTypes.ROOM_PHOTOS:
      return (
        <Card
          title="Room Photos"
          icon="images"
          onClick={handleClick}
          disabled={!url}
        />
      )
    case PreProjectDepositCardTypes.SCHEDULE_DESIGN_CALL:
      return (
        <Card
          disabled={!url}
          image={`${S3_ROOT}/images/app/illustrations/schedule-meeting.svg`}
          onClick={handleClick}
          title="Schedule an Initial Call"
        />
      )
    case PreProjectDepositCardTypes.SCHEDULE_PROJECT_CONSULTATION:
      return (
        <Card
          disabled={!url}
          image={`${S3_ROOT}/images/app/illustrations/schedule-meeting.svg`}
          onClick={handleClick}
          title="Schedule a Project Consultation"
        />
      )
    default:
      return null
  }
}

export default DashboardPreProjectDepositCard
