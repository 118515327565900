import React from 'react'

import { navigate } from 'gatsby'

import Checkout from 'components/app/user/checkout'
import PaymentModule from 'components/shared/payment-module'
import PaymentCard from 'components/shared/project/payment/card'
import useAuth from 'context/auth/use'
import { getDefaultPayment } from 'context/payment/utils'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import fireEvent from 'context/tracking/events'
import ThankYouCard from 'design-system/components/guide/thank-you-card'
import { Section } from 'design-system/components/guide/utils'
import { PaymentType } from 'types/payment'

const ProjectDeposit = () => {
  const { isFORMStaff } = useAuth()
  const { paymentProjectDepositPaid, project, refetch, isTradeProject } =
    useProject()
  const { createPaymentAndRefetch, loadingCreate } = useProjectPaymentMutate()

  const payment = getDefaultPayment(PaymentType.PROJECT_DEPOSIT, isTradeProject)

  return (
    <Section id="project-deposit">
      <PaymentModule
        hidePaymentRequest={isFORMStaff && !paymentProjectDepositPaid}
        paymentRequest={
          paymentProjectDepositPaid ? (
            <ThankYouCard
              onClick={() => navigate(`/app/projects/${project.id}`)}
            />
          ) : (
            <Checkout
              buttonText="Pay Project Deposit"
              includeInvoice={false}
              loading={loadingCreate}
              footnote="*$4999 is a non-refundable deposit towards your final cabinetry purchase."
              onPay={async (paymentSource, paymentMethod) => {
                await createPaymentAndRefetch({
                  variables: {
                    data: {
                      amount: payment.amount,
                      description: payment.description,
                      metadata: {
                        ...payment.metadata,
                        method: paymentMethod,
                      },
                      stripeSourceId: paymentSource?.id,
                      project: {
                        connect: { id: project.id },
                      },
                    },
                  },
                  onCompleted: async () => {
                    refetch()
                    fireEvent({
                      type: 'project_deposit_completed',
                    })
                  },
                })
              }}
              summary={<PaymentCard payment={payment} />}
              summaryTitle="Summary"
            />
          )
        }
        paymentType={PaymentType.PROJECT_DEPOSIT}
        title="Start revisions for $1,999"
      />
    </Section>
  )
}

export default ProjectDeposit
