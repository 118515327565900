import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText:
      'During the first call, you and your designer discuss <span class="bold">your project details, budget, timeline, and design direction.</span>',
    description:
      'A recap will be available on your dashboard to review after the call.',
    image: `images/interactive-guides/design-call/next-step-design-call-1.svg`,
  },
  {
    leadText:
      'To start the design process, <span class="bold">the next step is a $299 Design Deposit</span>, which you can pay <span class="bold">on your dashboard or via a payment link</span> provided during the call.',
    image: `images/interactive-guides/design-call/next-step-design-call-2.svg`,
  },
  {
    leadText:
      'After payment, your designer will send <span class="bold">a follow-up message on how to complete any remaining steps</span> before starting on the initial design.',
    description:
      'Don’t worry, any subsequent design changes can be made in the revisions stage.',
    image: `images/interactive-guides/design-call/next-step-design-call-3.svg`,
  },
  {
    leadText:
      'Your designer will <span class="bold">schedule the next call</span> to make sure you have everything you need at the presentation, which includes <span class="bold">samples in hand and photorealistic renders.</span>',
    description:
      'We expect your samples to arrive in 7-10 days.<br /> Additional samples can be purchased in sets of 3 for $90.',
    image: `images/interactive-guides/design-call/next-step-design-call-4.svg`,
  },
  {
    leadText:
      'At the <span class="bold">presentation</span>, your designer will <span class="bold">walk you through the initial design and pricing</span>, as well as discuss any detailed questions.',
    image: `images/interactive-guides/design-call/next-step-design-call-5.svg`,
  },
  {
    leadText:
      'To move forward into <span class="bold">design revisions</span>, we ask for a <span class="bold">$499 Project Deposit.</span>',
    image: `images/interactive-guides/design-call/next-step-design-call-06.svg`,
  },
]

const NextSteps = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel
          isTradeProject={isTradeProject}
          slideData={nextSteps}
        />
      </Grid>
    </Section>
  )
}

export default NextSteps
