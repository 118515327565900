import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  RowCardsPlaceholder,
  UserPlaceholder,
} from 'components/app/placeholder'
import DesignerHeaderHome from 'components/app/project/designer-header-home'
import NotificationBar from 'components/shared/notification-bar'
import {
  getOrderFormattedAdditional,
  getOrderFormattedName,
} from 'context/order/utils'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import { getRoomFormattedType } from 'context/room/utils'
import PrimaryCard from 'design-system/components/cards/primary-card'
import { Breakpoint } from 'styles/app/system'
import { RoomType } from 'types/room'
import * as viewUtils from 'views/utils'

import KeyDocuments from './key-documents'
import Resources from './resources'

const HomeMainDashboard = () => {
  const {
    loading,
    ordersCabinetryPublished,
    ordersIssuesPlaced,
    primaryDesigner: designer,
    project,
    projectDiscountHidden,
    projectDiscountText,
    roomsInDesign,
  } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()

  return (
    <>
      {loading ? (
        <Grid>
          <UserPlaceholder />
          <RowCardsPlaceholder />
          <Divider />
          <RowCardsPlaceholder items={2} noTitle size="small" />
          <RowCardsPlaceholder size="medium" />
        </Grid>
      ) : (
        <Grid stackable>
          <Grid.Row columns={2} verticalAlign="middle">
            <Grid.Column largeScreen={9} computer={10} tablet={16} mobile={16}>
              <DesignerHeaderHome project={project} designer={designer} />
            </Grid.Column>
            <NotificationColumn
              floated="right"
              largeScreen={6}
              computer={6}
              tablet={16}
              mobile={16}
            >
              {!projectDiscountHidden && projectDiscountText && (
                <NotificationBar
                  isDiscount
                  onHide={async () => {
                    await updateProjectAndRefetch({
                      variables: {
                        data: {
                          data: {
                            hiddenDiscount: true,
                          },
                        },
                        where: {
                          id: project.id,
                        },
                      },
                    })
                  }}
                  text={projectDiscountText}
                />
              )}
              {/* Add notifications here */}
              {designer?.data?.awayMessage ? (
                <NotificationBar text={designer.data.awayMessage} />
              ) : null}
            </NotificationColumn>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row stretched className="row-gap">
            {roomsInDesign.map((r) => (
              <Grid.Column
                key={r.id}
                widescreen={4}
                largeScreen={4}
                computer={5}
                tablet={8}
                mobile={16}
              >
                <PrimaryCard
                  image={viewUtils.getRoomImageByType(r.type)}
                  onClick={() =>
                    navigate(`/app/projects/${project.id}/rooms/${r.id}`)
                  }
                  title={getRoomFormattedType(r)}
                  subtitle={
                    r.type !== RoomType.Other && !!r.data?.descriptionAdditional
                      ? r.data.descriptionAdditional
                      : undefined
                  }
                />
              </Grid.Column>
            ))}
            {!!ordersIssuesPlaced.length && (
              <Grid.Column
                widescreen={4}
                largeScreen={4}
                computer={5}
                tablet={8}
                mobile={16}
              >
                <PrimaryCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/remedial-order.svg`}
                  onClick={() =>
                    navigate(
                      `/app/projects/${project.id}/add-ons-and-remedials`,
                    )
                  }
                  title="Resolution Orders"
                />
              </Grid.Column>
            )}
            {ordersCabinetryPublished.map((o) => (
              <Grid.Column
                key={o.id}
                widescreen={4}
                largeScreen={4}
                computer={5}
                tablet={8}
                mobile={16}
              >
                <PrimaryCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/order.svg`}
                  onClick={() =>
                    navigate(`/app/projects/${project.id}/orders/${o.id}`)
                  }
                  title={getOrderFormattedName(o)}
                  subtitle={getOrderFormattedAdditional(o)}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
          <KeyDocuments />
          <Resources />
        </Grid>
      )}
    </>
  )
}

const NotificationColumn = styled(Grid.Column)`
  @media ${Breakpoint.downFromComputer} {
    margin: 30px 0 0 !important;
  }
`

export default HomeMainDashboard
